import { Box, Button, Modal, Stack, Typography } from "@mui/material";

interface DeletionConfirmationProps {
	isOpen: boolean;
	resource: string;
	setIsOpen: (isOpen: boolean) => void;
	handleDelete: (resourceName?: string) => Promise<void>;
}

export const DeletionConfirmationModal = ({
	isOpen,
	resource,
	setIsOpen,
	handleDelete,
}: DeletionConfirmationProps) => {
	return (
		<Modal
			open={isOpen}
			onClose={() => setIsOpen(false)}
			aria-labelledby="delete-confirmation-modal"
		>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					borderRadius: "4px",
				}}
			>
				<Typography variant="h6" id="delete-confirmation-modal-title">
					Are you sure you want to delete {resource}?
				</Typography>
				<Stack direction="row" justifyContent="center" spacing={2} mt={2}>
					<Button variant="outlined" onClick={() => handleDelete(resource)}>
						Delete
					</Button>
					<Button variant="outlined" onClick={() => setIsOpen(false)}>
						Cancel
					</Button>
				</Stack>
			</Box>
		</Modal>
	);
};
