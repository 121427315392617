import { ThemeOptions } from "@mui/material";

export const componentsStyles = {
	MuiButton: {
		styleOverrides: {
			root: {
				textTransform: "none",
			},
		},
	},
	MuiTableHead: {
		styleOverrides: {
			root: {
				backgroundColor: "#E3E3E3",
			},
		},
	},
	MuiTableBody: {
		styleOverrides: {
			root: {
				backgroundColor: "#FFFFFF",
			},
		},
	},
	MuiTableFooter: {
		styleOverrides: {
			root: {
				backgroundColor: "#E3E3E3",
			},
		},
	},
	MuiTableCell: {
		styleOverrides: {
			root: {
				borderBottom: "1px solid #E3E3E3",
			},
		},
	},
} as ThemeOptions["components"];
