/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

type CustomAxisProps = {
	dataKey: string;
	ticks?: number[];
	domain?: number[];
	tickFormatter?: (v: number) => string;
	tickCount?: number;
};

type CustomLineProps = {
	dataKey: string;
	stroke?: string;
};

type CustomTooltipProps = {
	headerFormatter: (v: any) => string;
};

export type DotsChartProps = {
	data: any[];
	xAxis: CustomAxisProps;
	yAxis: CustomAxisProps;
	line: CustomLineProps;
	tooltip?: CustomTooltipProps;
};

const customTooltipGenerator =
	(genProps?: CustomTooltipProps) => (props: any) => {
		const { active, payload, label } = props;

		if (active && payload && payload.length) {
			const header = genProps?.headerFormatter
				? genProps?.headerFormatter(payload[0].value)
				: payload[0].value;

			return (
				<Paper>
					<Box p={1}>
						<Typography variant="body2" fontWeight={500}>
							{header}
						</Typography>
						<Typography variant="body2">
							{dayjs(label).format("D MMM, YYYY H:mm:ss")}
						</Typography>
					</Box>
				</Paper>
			);
		}

		return null;
	};

export const DotsChart = ({
	data,
	xAxis,
	yAxis,
	line,
	tooltip,
}: DotsChartProps) => {
	const CustomTooltip = customTooltipGenerator(tooltip);

	return (
		<ResponsiveContainer height={200}>
			<LineChart
				height={250}
				data={data}
				margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
			>
				<CartesianGrid strokeDasharray="3 3" vertical={false} />
				<XAxis
					type="number"
					{...xAxis}
					style={{
						fontSize: "0.8rem",
					}}
				/>
				<YAxis
					{...yAxis}
					style={{
						fontSize: "0.8rem",
					}}
					width={30}
				/>
				<Tooltip content={<CustomTooltip />} />
				<Line
					{...line}
					strokeWidth={2}
					isAnimationActive={false}
					dot={{ r: 2 }}
					activeDot={{ r: 5 }}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};
