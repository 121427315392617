import {
	AuthenticationResult,
	EventMessage,
	EventType,
	PublicClientApplication,
} from "@azure/msal-browser";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { msalConfig } from "@rdit-dps/arcane-sso-react";
import { BrowserRouter } from "react-router-dom";

import App from "./app/App";
import "./index.css";
import { theme } from "./theme";

const msalInstance = new PublicClientApplication(msalConfig);

type DashboardProps = {
	applications: string;
};

export default function Root(props: DashboardProps) {
	msalInstance.initialize();
	const accounts = msalInstance.getAllAccounts();
	if (accounts.length > 0) {
		msalInstance.setActiveAccount(accounts[0]);
	}

	msalInstance.addEventCallback((event: EventMessage) => {
		if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
			const payload = event.payload as AuthenticationResult;
			const { account } = payload;
			msalInstance.setActiveAccount(account);
		}
	});

	return (
		<BrowserRouter basename="health-probes">
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<App
					instance={msalInstance}
					applicationConfigString={props.applications}
				/>
			</ThemeProvider>
		</BrowserRouter>
	);
}
