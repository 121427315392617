import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";

import { CanaryConfig, CanaryRun } from "../../api";
import { CanariesTableRow } from "./CanariesTableRow";

export type CanariesTableProps = {
	canaries: CanaryConfig[];
	lastRuns: CanaryRun[];
	onStart: (canaryId: string) => Promise<void>;
	onStop: (canaryId: string) => Promise<void>;
	onDelete: (canaryId: string) => void;
	moreCanariesAvailable: boolean;
	handleGetNextCanaries: () => Promise<void>;
	moreLastRunsAvailable: boolean;
	handleGetNextLastRuns: () => Promise<void>;
};

export const CanariesTable = ({
	canaries,
	lastRuns,
	onStart,
	onStop,
	onDelete,
	moreCanariesAvailable,
	handleGetNextCanaries,
}: CanariesTableProps) => {
	const [page, setPage] = useState(0);
	const rowsPerPage = 20;

	useEffect(() => {
		if (canaries.length <= rowsPerPage) {
			setPage(0);
		}
	}, [canaries]);

	const itemsCount = moreCanariesAvailable ? -1 : canaries.length;

	const rows = canaries.slice(
		page * rowsPerPage,
		page * rowsPerPage + rowsPerPage
	);

	const handleChangePage = async (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		if (
			newPage > page &&
			canaries.length <= newPage * rowsPerPage &&
			moreCanariesAvailable
		) {
			await handleGetNextCanaries();
		}
		setPage(newPage);
	};

	return (
		<TableContainer>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell width={"30%"}>Name</TableCell>
						<TableCell>Ping Interval</TableCell>
						<TableCell>Last run status</TableCell>
						<TableCell>State</TableCell>
						<TableCell align="center">Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((c) => {
						const lastRun = lastRuns.find((run) => run.name === c.name);
						return (
							<CanariesTableRow
								key={c.name}
								canary={c}
								lastRun={lastRun}
								onStart={onStart}
								onStop={onStop}
								onDelete={onDelete}
							/>
						);
					})}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							colSpan={5}
							count={itemsCount}
							rowsPerPage={rowsPerPage}
							rowsPerPageOptions={[]}
							page={page}
							onPageChange={handleChangePage}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
};
