export enum CanaryRunStates {
	RUNNING = "RUNNING",
	PASSED = "PASSED",
	FAILED = "FAILED",
}

export enum CanaryRunStateReasonCodes {
	CANARY_FAILURE = "CANARY_FAILURE",
	EXECUTION_FAILURE = "EXECUTION_FAILURE",
}

export interface CanaryRunStatus {
	state: CanaryRunStates;
	stateReason?: string;
	stateReasonCode?: CanaryRunStateReasonCodes;
}

export interface CanaryRun {
	id: string;
	name: string;
	responseTime: number;
	timeline: {
		started: string;
		completed: string;
	};
	status: CanaryRunStatus;
}

export interface CanaryHistory {
	name: string;
	totalDowntime: number;
	uptime: number;
	averageResponseTime: number;
	runs: CanaryRun[];
}

export type GetCanaryHistoryResponse = CanaryHistory;

export type ListLastRunsResponse = {
	data: CanaryRun[];
	nextToken: string | null;
};
