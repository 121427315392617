import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import { Box, Divider, IconButton, TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";

import { CanaryConfig, CanaryRun } from "../../api";
import { useActionsButtonsState } from "../../hooks";
import { capitalizeFirstLetter } from "../../utils/capitalize";
import { CanaryRunStatus } from "./CanaryRunStatus";

export type CanariesTableRowProps = {
	canary: CanaryConfig;
	lastRun?: CanaryRun;
	onStart: (canaryId: string) => Promise<void>;
	onStop: (canaryId: string) => Promise<void>;
	onDelete: (canaryId: string) => void;
};

export const CanariesTableRow = ({
	canary,
	lastRun,
	onStart,
	onStop,
	onDelete,
}: CanariesTableRowProps) => {
	const { startDisabled, stopDisabled, deleteDisabled, disableActions } =
		useActionsButtonsState(canary.status.state);

	const formattedStatus = capitalizeFirstLetter(
		canary.status.state.toLowerCase()
	);

	const stopHandler = async () => {
		disableActions();
		await onStop(canary.name);
	};

	const startHandler = async () => {
		disableActions();
		await onStart(canary.name);
	};

	const deleteHandler = async () => {
		onDelete(canary.name);
	};

	return (
		<TableRow hover>
			<TableCell>
				<Link to={`/canaries/${canary.name}`}>{canary.name}</Link>
			</TableCell>
			<TableCell>{canary.pingInterval}</TableCell>
			<TableCell>
				<CanaryRunStatus status={lastRun?.status.state || null} />
			</TableCell>
			<TableCell>{formattedStatus}</TableCell>
			<TableCell>
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<IconButton
						aria-label="start"
						sx={{
							borderRadius: 10,
							color: "success.main",
						}}
						size="small"
						onClick={startHandler}
						color="inherit"
						disabled={startDisabled}
					>
						<PlayArrowRoundedIcon
							fontSize="large"
							color={startDisabled ? "disabled" : "success"}
							sx={{ cursor: "pointer" }}
						/>
					</IconButton>
					<Divider flexItem orientation="vertical" />
					<IconButton
						aria-label="stop"
						sx={{
							borderRadius: 10,
							color: "error.main",
						}}
						size="small"
						onClick={stopHandler}
						color="inherit"
						disabled={stopDisabled}
					>
						<StopRoundedIcon
							fontSize="large"
							color={stopDisabled ? "disabled" : "error"}
							sx={{ cursor: "pointer" }}
						/>
					</IconButton>
					<Divider flexItem orientation="vertical" />
					<IconButton
						aria-label="delete"
						sx={{
							borderRadius: 10,
							color: "error.main",
						}}
						size="small"
						onClick={deleteHandler}
						color="inherit"
						disabled={deleteDisabled}
					>
						<DeleteRoundedIcon
							fontSize="large"
							color={deleteDisabled ? "disabled" : "error"}
							sx={{ cursor: "pointer" }}
						/>
					</IconButton>
				</Box>
			</TableCell>
		</TableRow>
	);
};
