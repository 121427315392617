// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/fonts/LexiaDaMa.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'LexiaDaMa';
    src: local('LexiaDaMa'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
}

html {
  overflow: -moz-scrollbars-vertical; 
  overflow-y: scroll;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,mFAA8E;IAC9E,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;EACE,kCAAkC;EAClC,kBAAkB;AACpB;;AAEA;IACI,SAAS;IACT,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;IACI;iBACa;AACjB","sourcesContent":["@font-face {\n    font-family: 'LexiaDaMa';\n    src: local('LexiaDaMa'), url(../public/fonts/LexiaDaMa.ttf) format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n\nhtml {\n  overflow: -moz-scrollbars-vertical; \n  overflow-y: scroll;\n}\n\nbody {\n    margin: 0;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n        monospace;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
