import { Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const NotFoundPage = () => {
	return (
		<Container
			style={{
				height: "100vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Typography variant="h1" gutterBottom>
				404 Not Found 😮
			</Typography>
			<Typography variant="body1" paragraph>
				Oops! It seems like you've ventured into the unknown. Maybe try a
				different path?
			</Typography>
			<Button variant="outlined" color="primary" component={Link} to="/">
				Go Home
			</Button>
		</Container>
	);
};
