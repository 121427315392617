import { zodResolver } from "@hookform/resolvers/zod";
import {
	Button,
	Container,
	Paper,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { BackButton } from "@rdit-dps/arcane-ui-tool";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import { CanaryConfig } from "../../api";
import { useApi } from "../../hooks";
import { showNotification } from "../../utils";

const CanarySchema = z.object({
	pingInterval: z.coerce.number().positive(),
});

type FormValues = z.infer<typeof CanarySchema>;

type Props = {
	canary?: CanaryConfig;
};

export const EditCanary: React.FC = (props: Props) => {
	const navigate = useNavigate();
	const { name } = useParams<"name">();
	const [canary, setCanary] = useState<CanaryConfig>();
	const { getCanary, updateCanary } = useApi();
	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm<FormValues>({
		resolver: zodResolver(CanarySchema),
		defaultValues: {
			pingInterval: 0,
		},
	});

	useEffect(() => {
		if (!name) {
			navigate("/404");
			return;
		}

		const fetchData = async () => {
			const { data } = await getCanary(name);
			if (data) {
				setCanary(data);
				reset(data);
			}
		};
		if (props.canary) {
			setCanary(props.canary);
			reset(props.canary);
		} else {
			fetchData();
		}
	}, []);

	const handleFormSubmit = async (canaryId: string, data: FormValues) => {
		const { error } = await updateCanary(canaryId, data);
		if (!error) {
			showNotification("success", "Successfully updated configuration");
			navigate(`/canaries/${canaryId}`);
		}
	};

	return (
		<Container sx={{ marginBottom: 2 }}>
			<Stack direction="row" spacing={2} my={1} alignItems="center">
				<BackButton
					href={canary?.name ? `/canaries/${canary?.name}` : "/canaries"}
				/>
				<Typography variant="h4" my={2}>
					Edit {canary?.name}
				</Typography>
			</Stack>
			{!!canary && (
				<form
					onSubmit={handleSubmit((data) => handleFormSubmit(canary.name, data))}
				>
					<Paper sx={{ p: 2 }} elevation={0}>
						<Stack direction="column" spacing={2} mb={1}>
							<TextField
								value={canary.name}
								disabled
								size="small"
								sx={{ maxWidth: "500px" }}
								label="Name"
							/>
							<TextField
								value={canary.endpoint}
								disabled
								size="small"
								sx={{ maxWidth: "500px" }}
								label="Endpoint"
							/>
							<TextField
								{...register("pingInterval")}
								size="small"
								sx={{ maxWidth: "500px" }}
								inputProps={{ min: 1 }}
								type="number"
								label="Ping Interval"
								error={!!errors.pingInterval}
								helperText={errors.pingInterval?.message}
							/>
						</Stack>
					</Paper>

					<Stack direction="row" spacing={2} my={1} justifyContent={"center"}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							sx={{ color: "white " }}
						>
							Submit
						</Button>
					</Stack>
				</form>
			)}
		</Container>
	);
};
