import dayjs from "dayjs";

export const calcAxisTimestamps = (rangeInHours: number, intervals = 12) => {
	const endTimestamp = dayjs();

	const startTimestamp = endTimestamp.subtract(rangeInHours, "hours");

	// Calculate the tick interval based on the range
	const tickInterval = Math.ceil((rangeInHours * 60) / intervals);

	const closestMinuteBefore = endTimestamp
		.subtract(endTimestamp.minute() % tickInterval, "minutes")
		.startOf("minute");

	// Generate an array of timestamps at calculated intervals up to the closest fifteenth minute before now
	const xAxisTicks = Array.from({ length: intervals }, (_, index) =>
		closestMinuteBefore.subtract(index * tickInterval, "minutes").valueOf()
	).reverse();

	return { startTimestamp, endTimestamp, xAxisTicks };
};
