import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";

import { CanaryConfig, CanaryHistory } from "../../api";
import { CanaryRunStatus } from "./CanaryRunStatus";

export type CanaryDetailsSummaryProps = {
	canary: CanaryConfig;
	canaryHistory: CanaryHistory;
};

export const CanaryDetailsSummary = ({
	canary,
	canaryHistory,
}: CanaryDetailsSummaryProps) => {
	return (
		<TableContainer component={Paper} elevation={0}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell colSpan={4}>
							<Typography variant="h6">Summary</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>
							<Box>
								<Typography variant="body2" color="GrayText">
									Latest Run
								</Typography>
								<CanaryRunStatus status={canaryHistory.runs[0]?.status.state} />
							</Box>
						</TableCell>
						<TableCell>
							<Box>
								<Typography variant="body2" color="GrayText">
									Total downtime
								</Typography>
								<Typography variant="body2">
									{canaryHistory.totalDowntime}
								</Typography>
							</Box>
						</TableCell>
						<TableCell>
							<Box>
								<Typography variant="body2" color="GrayText">
									Uptime
								</Typography>
								<Typography variant="body2">{canaryHistory.uptime}%</Typography>
							</Box>
						</TableCell>
						<TableCell>
							<Box>
								<Typography variant="body2" color="GrayText">
									State
								</Typography>
								<Typography variant="body2">{canary.status.state}</Typography>
							</Box>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};
