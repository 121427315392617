import { zodResolver } from "@hookform/resolvers/zod";
import AddIcon from "@mui/icons-material/Add";
import {
	Button,
	Chip,
	Container,
	IconButton,
	Paper,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { BackButton } from "@rdit-dps/arcane-ui-tool";
import React from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { CreateCanaryConfig } from "../../api";
import { useApi } from "../../hooks";
import { showNotification } from "../../utils";

const CanarySchema = z.object({
	name: z
		.string()
		.min(1)
		.regex(
			/^[0-9a-z_-]+$/,
			"Invalid name. Only lowercase letters, numbers, underscores, and hyphens are allowed."
		)
		.max(21),
	endpoint: z.string().url(),
	pingInterval: z.coerce.number().positive().min(1),
	emailRecipients: z
		.array(z.object({ email: z.string().email() }))
		.min(1, "At least one recipient is required"),
	email: z.string().email().optional().or(z.literal("")),
});

type FormValues = z.infer<typeof CanarySchema>;

export const CreateCanary: React.FC = () => {
	const {
		handleSubmit,
		control,
		register,
		watch,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "all",
		resolver: zodResolver(CanarySchema),
		defaultValues: {
			name: "",
			endpoint: "",
			pingInterval: 1,
			emailRecipients: [],
			email: "",
		},
	});

	const { createCanary } = useApi();
	const navigate = useNavigate();

	const {
		fields: emailFields,
		append: appendEmail,
		remove: removeEmail,
	} = useFieldArray({
		control,
		name: "emailRecipients",
	});

	const addEmail = () => {
		const email = getValues("email");
		if (email) {
			appendEmail({
				email,
			});
			setValue("email", "");
		}
	};

	const handleFormSubmit: SubmitHandler<FormValues> = async (data) => {
		const requestData: CreateCanaryConfig = {
			name: data.name,
			endpoint: data.endpoint,
			pingInterval: data.pingInterval,
			locations: ["eu-west-1"],
			alertConfig: {
				tlsWarningThreshold: 30,
				tlsErrorThreshold: 7,
				emailRecipients: data.emailRecipients.map((e) => e.email),
			},
		};
		const { error } = await createCanary(requestData);
		if (!error) {
			showNotification("success", "Successfully created new configuration");
			navigate("/canaries");
		}
	};

	return (
		<Container sx={{ marginBottom: 2 }}>
			<Stack direction="row" spacing={2} my={1} alignItems="center">
				<BackButton href="/canaries" />
				<Typography variant="h4" my={2}>
					Create Canary
				</Typography>
			</Stack>
			<form onSubmit={handleSubmit(handleFormSubmit)}>
				<Paper sx={{ p: 2 }} elevation={0}>
					<Stack direction="column" spacing={2} mb={1}>
						<TextField
							{...register("name")}
							size="small"
							sx={{ maxWidth: "500px" }}
							label="Name"
							error={!!errors.name}
							helperText={errors.name?.message}
						/>
						<TextField
							{...register("endpoint")}
							size="small"
							sx={{ maxWidth: "500px" }}
							label="Endpoint"
							error={!!errors.endpoint}
							helperText={errors.endpoint?.message}
						/>
						<TextField
							{...register("pingInterval")}
							size="small"
							sx={{ maxWidth: "500px" }}
							type="number"
							inputProps={{ min: 1 }}
							label="Ping Interval (in minutes)"
							error={!!errors.pingInterval}
							helperText={errors.pingInterval?.message}
						/>
					</Stack>
				</Paper>

				<Paper sx={{ p: 2, my: 2 }} elevation={0}>
					<Typography variant="subtitle1" gutterBottom>
						Email Recipients
					</Typography>
					<Stack direction="row" sx={{ flexWrap: "wrap" }}>
						{emailFields.map((field, index) => (
							<Chip
								key={field.id}
								label={field.email}
								onDelete={() => removeEmail(index)}
								variant="outlined"
								color="primary"
								sx={{
									p: 0.5,
									m: 0.5,
									"& .MuiChip-label": {
										overflow: "visible",
									},
								}}
							/>
						))}
					</Stack>
					<Stack direction="row" alignItems="center" spacing={2} my={1}>
						<TextField
							{...register("email")}
							size="small"
							sx={{ maxWidth: "500px" }}
							fullWidth
							label="Email"
							error={!!errors.email || !!errors.emailRecipients}
							helperText={
								errors.email?.message || errors.emailRecipients?.message
							}
						/>
						<IconButton
							size="small"
							onClick={addEmail}
							disabled={!!errors.email || !watch("email")}
							color="primary"
						>
							<AddIcon sx={{ cursor: "pointer" }} />
						</IconButton>
					</Stack>
				</Paper>

				<Stack direction="row" spacing={2} my={1} justifyContent={"center"}>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						sx={{ color: "white " }}
					>
						Submit
					</Button>
				</Stack>
			</form>
		</Container>
	);
};
