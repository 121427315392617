import {
	CanaryDetails,
	CreateCanary,
	EditCanary,
	ListCanaries,
	LoginPage,
	NotFoundPage, // Reports,
} from "../pages";

interface Route {
	path: string;
	element: React.ReactNode;
	title: string;
}

export const navRoutes: Route[] = [
	{
		path: "/canaries",
		element: <ListCanaries />,
		title: "Canaries",
	},
	// {
	// 	path: "/reports",
	// 	element: <Reports />,
	// 	title: "Reports",
	// },
];

export const publicRoutes: Route[] = [
	{ path: "/", element: <LoginPage />, title: "Login" },
	{ path: "/404", element: <NotFoundPage />, title: "Not Found" },
];

export const privateRoutes: Route[] = [
	...navRoutes,
	{
		path: "/canaries/:name",
		element: <CanaryDetails />,
		title: "Canary Details",
	},
	{
		path: "/canaries/create",
		element: <CreateCanary />,
		title: "Create Canary",
	},
	{
		path: "/canaries/:name/edit",
		element: <EditCanary />,
		title: "Edit Canary",
	},
];
