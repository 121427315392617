import { createTheme, responsiveFontSizes } from "@mui/material";
import { deepmerge } from "@mui/utils";

import { componentsStyles } from "./components";
import { paletteStyles } from "./palette";
import { typographyStyles } from "./typography";

const initTheme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
});

const customizedTheme = createTheme({
	components: componentsStyles,
	palette: paletteStyles,
	typography: typographyStyles,
});

export const theme = responsiveFontSizes(deepmerge(initTheme, customizedTheme));
