import { Container, Stack, Typography } from "@mui/material";
import { Button, Spinner } from "@rdit-dps/arcane-ui-tool";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CanaryConfig, CanaryRun } from "../../api";
import { DeletionConfirmationModal } from "../../components/DeletionConfirmationModal";
import { CanariesTable } from "../../components/canaries/CanariesTable";
import { useApi } from "../../hooks";
import { showNotification } from "../../utils";

export const ListCanaries = () => {
	const navigate = useNavigate();
	const {
		isLoading,
		startCanary,
		pauseCanary,
		listCanaries,
		listLastRuns,
		deleteCanary,
	} = useApi();
	const [selectedCanary, setSelectedCanary] = useState<string>("");
	const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
	const [canaries, setCanaries] = useState<CanaryConfig[]>([]);
	const [canariesNextToken, setCanariesNextToken] = useState<string | null>(
		null
	);
	const [lastRuns, setLastRuns] = useState<CanaryRun[]>([]);
	const [lastRunsNextToken, setLastRunsNextToken] = useState<string | null>(
		null
	);

	const fetchData = async () => {
		const { data: canariesData } = await listCanaries();
		const { data: lastRunsData } = await listLastRuns();

		if (canariesData?.data?.length && canariesData?.data?.length > 0) {
			setCanaries(canariesData.data);
			setCanariesNextToken(canariesData.nextToken);
		}
		if (lastRunsData?.data?.length && lastRunsData?.data?.length > 0) {
			setLastRuns(lastRunsData.data);
			setLastRunsNextToken(lastRunsData.nextToken);
		}
	};

	const handleStart = async (canaryName: string) => {
		const { error } = await startCanary(canaryName);
		if (!error) {
			await fetchData();
			showNotification("success", `Successfully began starting ${canaryName}`);
			// setTimeout(async () => {
			// 	await fetchData();
			// }, 5000);
		}
	};

	const handleStop = async (canaryName: string) => {
		const { error } = await pauseCanary(canaryName);
		if (!error) {
			await fetchData();
			showNotification("success", `Successfully began stopping ${canaryName}`);
			// setTimeout(async () => {
			// 	await fetchData();
			// }, 5000);
		}
	};

	const handleDelete = async () => {
		setIsConfirmationOpen(false);
		if (selectedCanary) {
			const { error } = await deleteCanary(selectedCanary);
			if (!error) {
				await fetchData();
				showNotification("success", `Successfully deleted ${selectedCanary}`);
			}
		}
	};

	const handleGetNextCanaries = async () => {
		const { data: canariesData } = await listCanaries(canariesNextToken);
		if (canariesData?.data.length && canariesData?.data.length > 0) {
			setCanaries([...canaries, ...canariesData.data]);
			setCanariesNextToken(canariesData.nextToken);
		}
	};

	const handleGetNextLastRuns = async () => {
		const { data: lastRunsData } = await listLastRuns(lastRunsNextToken);
		if (lastRunsData?.data.length && lastRunsData?.data.length > 0) {
			setLastRuns([...lastRuns, ...lastRunsData.data]);
			setLastRunsNextToken(lastRunsData.nextToken);
		}
	};

	const handleDeleteConfirmation = (canaryName: string) => {
		setSelectedCanary(canaryName);
		setIsConfirmationOpen(true);
	};

	const handleCreateClick = () => {
		navigate(`/canaries/create`);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<Container sx={{ marginBottom: 2 }}>
			{isLoading && <Spinner fullscreen opacity={0.5} />}
			<Stack direction="row-reverse" spacing={2} my={2}>
				<Button variant="outlined" onClick={handleCreateClick}>
					<Typography>Create canary</Typography>
				</Button>
			</Stack>
			<CanariesTable
				canaries={canaries}
				lastRuns={lastRuns}
				onStart={handleStart}
				onStop={handleStop}
				onDelete={handleDeleteConfirmation}
				moreCanariesAvailable={!!canariesNextToken}
				handleGetNextCanaries={handleGetNextCanaries}
				moreLastRunsAvailable={!!lastRunsNextToken}
				handleGetNextLastRuns={handleGetNextLastRuns}
			/>
			<DeletionConfirmationModal
				isOpen={isConfirmationOpen}
				setIsOpen={setIsConfirmationOpen}
				handleDelete={handleDelete}
				resource={selectedCanary}
			/>
		</Container>
	);
};
