export enum CanaryStates {
	CREATING = "CREATING",
	READY = "READY",
	STARTING = "STARTING",
	RUNNING = "RUNNING",
	UPDATING = "UPDATING",
	STOPPING = "STOPPING",
	STOPPED = "STOPPED",
	ERROR = "ERROR",
	DELETING = "DELETING",
}

export enum CanaryStateReasonCodes {
	INVALID_PERMISSIONS = "INVALID_PERMISSIONS",
	CREATE_PENDING = "CREATE_PENDING",
	CREATE_IN_PROGRESS = "CREATE_IN_PROGRESS",
	CREATE_FAILED = "CREATE_FAILED",
	UPDATE_PENDING = "UPDATE_PENDING",
	UPDATE_IN_PROGRESS = "UPDATE_IN_PROGRESS",
	UPDATE_COMPLETE = "UPDATE_COMPLETE",
	ROLLBACK_COMPLETE = "ROLLBACK_COMPLETE",
	ROLLBACK_FAILED = "ROLLBACK_FAILED",
	DELETE_IN_PROGRESS = "DELETE_IN_PROGRESS",
	DELETE_FAILED = "DELETE_FAILED",
	SYNC_DELETE_IN_PROGRESS = "SYNC_DELETE_IN_PROGRESS",
}

interface CanaryStatus {
	state: CanaryStates;
	stateReason?: string;
	stateReasonCode?: CanaryStateReasonCodes;
}
export interface CanaryConfig {
	name: string;
	status: CanaryStatus;
	endpoint: string;
	pingInterval: number;
	locations: string[];
	alertConfig: {
		tlsWarningThreshold: number;
		tlsErrorThreshold: number;
		jiraConfig: {
			project: string;
			issueType: string;
			priority: string;
			labels: {
				key: string;
				value: string;
			}[];
			customFields: {
				key: string;
				value: string;
			}[];
		};
		opsGenieConfig: {
			entity: string;
			tags: {
				key: string;
				value: string;
			}[];
			priority: string;
		};
		emailRecipients: string[];
	};
}

export type GetCanaryResponse = CanaryConfig;

export type ListCanariesResponse = {
	data: CanaryConfig[];
	nextToken: string | null;
};
