import { Grid, Paper, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

import { CanaryHistory, CanaryRunStates } from "../../../api";
import { DotsChart, DotsChartProps } from "./DotsChart";
import { calcAxisTimestamps } from "./utils";

type RunChartData = {
	startedAt: number;
	availability: number;
	duration: number;
	okResponses: number;
	errorResponses: number;
	id: string;
	label: string;
};

const durationChartProps = (
	data: RunChartData[],
	xAxisProps: { ticks: number[]; domain: number[] }
): DotsChartProps => ({
	data,
	xAxis: {
		dataKey: "startedAt",
		tickFormatter: (v: number) => dayjs(v).format("H:mm"),
		...xAxisProps,
	},
	yAxis: {
		dataKey: "duration",
		tickFormatter: (v: number) => `${(v / 1000).toFixed(1)}s`,
		tickCount: 3,
	},
	line: {
		dataKey: "duration",
		stroke: "#FFB020",
	},
	tooltip: {
		headerFormatter: (v: number) => `Duration: ${v / 1000}s`,
	},
});

const successChartProps = (
	data: RunChartData[],
	xAxisProps: { ticks: number[]; domain: number[] }
): DotsChartProps => ({
	data,
	xAxis: {
		dataKey: "startedAt",
		tickFormatter: (v: number) => dayjs(v).format("H:mm"),
		...xAxisProps,
	},
	yAxis: {
		dataKey: "availability",
		tickFormatter: (v: number) => `${v}%`,
		tickCount: 3,
	},
	line: {
		dataKey: "availability",
	},
	tooltip: {
		headerFormatter: (v: number) => `Success: ${v}%`,
	},
});

const okRespChartProps = (
	data: RunChartData[],
	xAxisProps: { ticks: number[]; domain: number[] }
): DotsChartProps => ({
	data,
	xAxis: {
		dataKey: "startedAt",
		tickFormatter: (v: number) => dayjs(v).format("H:mm"),
		...xAxisProps,
	},
	yAxis: {
		dataKey: "okResponses",
		tickCount: 3,
	},
	line: {
		dataKey: "okResponses",
		stroke: "#2E7D32",
	},
	tooltip: {
		headerFormatter: (v: number) => `2xx count: ${v}`,
	},
});

const errorRespChartProps = (
	data: RunChartData[],
	xAxisProps: { ticks: number[]; domain: number[] }
): DotsChartProps => ({
	data,
	xAxis: {
		dataKey: "startedAt",
		tickFormatter: (v: number) => dayjs(v).format("H:mm"),
		...xAxisProps,
	},
	yAxis: {
		dataKey: "errorResponses",
		tickCount: 3,
	},
	line: {
		dataKey: "errorResponses",
		stroke: "#FFB020",
	},
	tooltip: {
		headerFormatter: (v: number) => `4xx count: ${v}`,
	},
});

const faultRespChartProps = (
	data: RunChartData[],
	xAxisProps: { ticks: number[]; domain: number[] }
): DotsChartProps => ({
	data,
	xAxis: {
		dataKey: "startedAt",
		tickFormatter: (v: number) => dayjs(v).format("H:mm"),
		...xAxisProps,
	},
	yAxis: {
		dataKey: "errorResponses",
		tickCount: 3,
	},
	line: {
		dataKey: "errorResponses",
		stroke: "#D14343",
	},
	tooltip: {
		headerFormatter: (v: number) => `5xx count: ${v}`,
	},
});

const formatData = (data: CanaryHistory["runs"], startTimestamp: Dayjs) => {
	const result: RunChartData[] = [];
	// eslint-disable-next-line no-plusplus
	for (let i = data.length - 1; i >= 0; i--) {
		const run = data[i];
		const startedAt = dayjs(run.timeline.started);
		const passed = run.status.state === CanaryRunStates.PASSED;
		if (startedAt.isAfter(startTimestamp)) {
			const formattedRunData: RunChartData = {
				startedAt: startedAt.valueOf(),
				availability: passed ? 100 : 0,
				duration: run.responseTime,
				okResponses: passed ? 1 : 0,
				errorResponses: passed ? 0 : 1,
				id: run.id,
				label: run.id,
			};
			result.push(formattedRunData);
		}
	}
	return result;
};

type Props = {
	canaryHistory: CanaryHistory;
};

export const MonitoringCharts = ({ canaryHistory }: Props) => {
	const rangeInHours = 3;
	const { startTimestamp, endTimestamp, xAxisTicks } = calcAxisTimestamps(
		rangeInHours,
		6
	);
	const chartData = formatData(canaryHistory.runs, startTimestamp);
	const xAxisProps = {
		ticks: xAxisTicks,
		domain: [startTimestamp.valueOf(), endTimestamp.valueOf()],
	};
	const charts = [
		{
			title: "Duration",
			props: durationChartProps(chartData, xAxisProps),
		},
		{
			title: "Success rate",
			props: successChartProps(chartData, xAxisProps),
		},
		{
			title: "Ok (2xx)",
			props: okRespChartProps(chartData, xAxisProps),
		},
		{
			title: "Errors (4xx)",
			props: errorRespChartProps(chartData, xAxisProps),
		},
		{
			title: "Faults (5xx)",
			props: faultRespChartProps(chartData, xAxisProps),
		},
	];

	return (
		<Grid container spacing={2}>
			{charts.map(({ title, props }, i) => (
				<Grid item xs={12} md={6} key={`monitoring-chart-${i}`}>
					<Paper sx={{ backgroundColor: "white", p: 1 }} elevation={0}>
						<Typography variant="h6" ml={2} my={1}>
							{title}
						</Typography>
						<DotsChart {...props} />
					</Paper>
				</Grid>
			))}
		</Grid>
	);
};
