import { AxiosError } from "axios";

import { showNotification } from "./showNotification";

export const displayError = (error: unknown) => {
	if (error instanceof AxiosError) {
		showNotification("error", error.message);
		return;
	}
	if (error instanceof Error) {
		showNotification("error", error.message);
		return;
	}
	showNotification("error", "Unknown error");
};
