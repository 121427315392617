import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Toaster } from "react-hot-toast";

import { Router } from "./Router";

type AppProps = {
	instance: PublicClientApplication;
	applicationConfigString: string;
};

export default function App({ instance, applicationConfigString }: AppProps) {
	return (
		<MsalProvider instance={instance}>
			<Router applicationConfigString={applicationConfigString} />
			<Toaster />
		</MsalProvider>
	);
}
