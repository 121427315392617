import { Paper, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import dayjs, { Dayjs } from "dayjs";
import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

import { CanaryHistory, CanaryRunStates } from "../../../api";
import { calcAxisTimestamps } from "./utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTooltip = (props: any) => {
	const { active, payload, label } = props;

	if (active && payload && payload.length) {
		return (
			<Paper>
				<Box p={1}>
					<Typography
						variant="body2"
						fontWeight={500}
					>{`Availability: ${payload[0].value}%`}</Typography>
					<Typography variant="body2">
						{dayjs(label).format("D MMM, YYYY H:mm:ss")}
					</Typography>
				</Box>
			</Paper>
		);
	}

	return null;
};

const commonDotProps = (color: string) => ({
	dot: {
		opacity: 1,
		fill: color,
		stroke: color,
		strokeWidth: 2,
		r: 4,
	},
	activeDot: {
		opacity: 1,
		fill: color,
		stroke: color,
		strokeWidth: 2,
		r: 5,
	},
});

export const formatData = (
	data: CanaryHistory["runs"],
	startTimestamp: Dayjs
) => {
	const result = [];
	// eslint-disable-next-line no-plusplus
	for (let i = data.length - 1; i >= 0; i--) {
		const run = data[i];
		const startedAt = dayjs(run.timeline.started);
		const passed = run.status.state === CanaryRunStates.PASSED;
		if (startedAt.isAfter(startTimestamp)) {
			const formattedRunData = {
				startedAt: startedAt.valueOf(),
				availability: passed ? 100 : 0,
				passed: passed ? 100 : null,
				failed: !passed ? 0 : null,
				id: run.id,
				label: run.id,
			};
			result.push(formattedRunData);
		}
	}
	return result;
};

type Props = {
	data: CanaryHistory["runs"];
};

export const CanaryAvailabilityChart = ({ data }: Props) => {
	const { palette } = useTheme();
	const rangeInHours = 3;

	const { startTimestamp, endTimestamp, xAxisTicks } =
		calcAxisTimestamps(rangeInHours);
	const chartData = formatData(data, startTimestamp);

	return (
		<ResponsiveContainer height={200}>
			<LineChart width={730} height={200} data={chartData} margin={{ top: 15 }}>
				<CartesianGrid strokeDasharray="3 3" vertical={false} />
				<XAxis
					type="number"
					domain={[startTimestamp.valueOf(), endTimestamp.valueOf()]}
					dataKey="startedAt"
					ticks={xAxisTicks}
					tickFormatter={(v) => dayjs(v).format("H:mm")}
					style={{
						fontSize: "0.8rem",
					}}
				/>
				<YAxis
					domain={[0, 100]}
					tickCount={3}
					tickFormatter={(v) => `${v}%`}
					dataKey={"availability"}
					style={{
						fontSize: "0.8rem",
					}}
				/>
				<Line
					opacity={0}
					dataKey="passed"
					stroke={palette.info.main}
					{...commonDotProps(palette.info.main)}
					isAnimationActive={false}
					strokeWidth={2}
				/>
				<Line
					opacity={0}
					dataKey="failed"
					stroke={palette.error.main}
					{...commonDotProps(palette.error.main)}
					isAnimationActive={false}
					strokeWidth={2}
				/>
				<Tooltip position={{ y: 30 }} content={<CustomTooltip />} />
			</LineChart>
		</ResponsiveContainer>
	);
};
