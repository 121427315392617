import { ApiResponse, useFetchWithMsal } from "@rdit-dps/arcane-sso-react";
import qs from "qs";

import {
	CreateCanaryRequest,
	CreateCanaryResponse,
	GetCanaryHistoryResponse,
	GetCanaryResponse,
	ListCanariesResponse,
	ListCanaryReportsResponse,
	ListLastRunsResponse,
	PauseCanaryResponse,
	ResumeCanaryResponse,
	UpdateCanaryRequest,
	UpdateCanaryResponse,
} from "../api";
import { displayError } from "../utils/displayError";

export const useApi = () => {
	const { get, post, patch, del, isLoading } = useFetchWithMsal(displayError);

	return {
		isLoading,
		createCanary: async (
			data: CreateCanaryRequest
		): Promise<ApiResponse<CreateCanaryResponse>> => {
			return post("/canaries", data);
		},
		updateCanary: async (
			name: string,
			data: UpdateCanaryRequest
		): Promise<ApiResponse<UpdateCanaryResponse>> => {
			return patch<UpdateCanaryRequest, UpdateCanaryResponse>(
				`/canaries/${name}`,
				data
			);
		},
		listCanaries: async (
			nextToken?: string | null
		): Promise<ApiResponse<ListCanariesResponse>> => {
			const params = { ...(nextToken && { nextToken }) };
			const queryString = qs.stringify(params);
			const url = `/canaries${queryString ? `?${queryString}` : ""}`;
			return get<ListCanariesResponse>(url);
		},
		listLastRuns: async (
			nextToken?: string | null
		): Promise<ApiResponse<ListLastRunsResponse>> => {
			const params = { ...(nextToken && { nextToken }) };
			const queryString = qs.stringify(params);
			const url = `/canaries/last-runs${queryString ? `?${queryString}` : ""}`;
			return get<ListLastRunsResponse>(url);
		},
		getCanary: async (
			name: string
		): Promise<ApiResponse<GetCanaryResponse>> => {
			return get<GetCanaryResponse>(`/canaries/${name}`);
		},
		getCanaryHistory: async (
			name: string
		): Promise<ApiResponse<GetCanaryHistoryResponse>> => {
			return get<GetCanaryHistoryResponse>(`/canaries/${name}/history`);
		},
		pauseCanary: async (
			name: string
		): Promise<ApiResponse<PauseCanaryResponse>> => {
			return post<PauseCanaryResponse>(`/canaries/${name}/pause`);
		},
		startCanary: async (
			name: string
		): Promise<ApiResponse<ResumeCanaryResponse>> => {
			return post<ResumeCanaryResponse>(`/canaries/${name}/resume`);
		},
		deleteCanary: async (name: string): Promise<ApiResponse<null>> => {
			return del(`/canaries/${name}`);
		},
		listCanaryReports: async (): Promise<
			ApiResponse<ListCanaryReportsResponse>
		> => {
			return get<ListCanaryReportsResponse>("/reports");
		},
		getCanaryReport: async (
			name: string
		): Promise<ApiResponse<GetCanaryResponse>> => {
			return get<GetCanaryResponse>(`/reports/${name}`);
		},
		// TBD
		// scheduleCanaryReport: async (data: any): Promise<AxiosResponse<any>> => {
		// 	return post<any, any>("/reports", data);
		// },
	};
};
