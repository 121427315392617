import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RotateRightRoundedIcon from "@mui/icons-material/RotateRightRounded";
import { Box, Typography } from "@mui/material";

import { CanaryRunStates } from "../../api";
import { capitalizeFirstLetter } from "../../utils/capitalize";

type Props = {
	status: CanaryRunStates | null;
};

const renderIcon = (status: CanaryRunStates | null) => {
	if (status === CanaryRunStates.RUNNING) {
		return <RotateRightRoundedIcon fontSize="small" color="secondary" />;
	}
	if (status === CanaryRunStates.PASSED) {
		return <CheckCircleOutlineIcon fontSize="small" color="success" />;
	}
	if (status === CanaryRunStates.FAILED) {
		return <HighlightOffIcon fontSize="small" color="error" />;
	}
	return <HelpOutlineRoundedIcon fontSize="small" color="secondary" />;
};

const getColor = (status: CanaryRunStates | null) => {
	if (status === CanaryRunStates.PASSED) {
		return "success.main";
	}
	if (status === CanaryRunStates.FAILED) {
		return "error.main";
	}
	return "secondary.main";
};

const processStatus = (status: CanaryRunStates | null) => {
	if (!status) {
		return "No data";
	}
	return capitalizeFirstLetter(status?.toLowerCase());
};

export const CanaryRunStatus = ({ status }: Props) => {
	const color = getColor(status);
	const processedStatus = processStatus(status);

	return (
		<Box sx={{ display: "flex", alignItems: "center" }}>
			{renderIcon(status)}
			<Typography
				variant="body2"
				color={color}
				sx={{ marginLeft: "4px", fontWeight: 500 }}
			>
				{processedStatus}
			</Typography>
		</Box>
	);
};
