import { useEffect, useState } from "react";

import { CanaryStates } from "../api";

export const useActionsButtonsState = (canaryStatus: CanaryStates) => {
	const [actionsDisabled, setActionsDisabled] = useState(false);
	const [deletionDisabled, setDeletionDisabled] = useState(false);

	const disableActions = () => {
		setActionsDisabled(true);
	};

	useEffect(() => {
		const inProgressStatuses = [
			CanaryStates.CREATING,
			CanaryStates.STARTING,
			CanaryStates.DELETING,
			CanaryStates.CREATING,
			CanaryStates.STOPPING,
			CanaryStates.UPDATING,
		];

		const shouldDisableDelete = inProgressStatuses.includes(canaryStatus);
		setDeletionDisabled(shouldDisableDelete);

		const shouldDisableActins = [
			...inProgressStatuses,
			CanaryStates.ERROR,
		].includes(canaryStatus);

		setActionsDisabled(shouldDisableActins);
	}, [canaryStatus]);

	const isActive = canaryStatus === CanaryStates.RUNNING;

	const startDisabled = actionsDisabled || isActive;
	const stopDisabled = actionsDisabled || !isActive;
	const deleteDisabled = deletionDisabled || isActive;

	return { startDisabled, stopDisabled, deleteDisabled, disableActions };
};
